<template>
	<div>
		<a v-if="phone" :href="'tel:'+call" class="btn btn-outline-dark mr-2">
			<i class="fa fa-phone"></i>
		</a>
		{{phone}}
	</div>
</template>

<script>
	export default {
		name: "Tel",
		props: ['value'],
		data: function () {
			return {phone: ''}
		},
		mounted: function () {
			this.phone = this.$options.filters.trim(this.value);
			if (this.phone == '06') {
				this.phone = '';
			}
		},
		computed: {
			call: function () {
				return this.phone.replace(/[^\d\+]/g, '');
			}
		}
	}
</script>

<style scoped>

</style>