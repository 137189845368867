<template>
	<div class="row no-gutters justify-content-center bg-body-dark">
		<div class="hero-static col-sm-10 col-md-8 col-xl-6 d-flex align-items-center p-2 px-sm-0">
			<div class="block block-rounded block-transparent block-fx-pop w-100 mb-0 overflow-hidden">
				<div class="row no-gutters">
					<div class="col bg-white">
						<div class="block-content block-content-full px-lg-5 py-md-5 py-lg-6">
							<div class="mb-2 text-center">
								<div class="font-w700 font-size-h1">
									<span class="text-dark">eKo</span><span class="text-primary">Intervention</span>
								</div>
								<p v-if="busy">Enregistrement...</p>
								<p class="text-uppercase font-w700 font-size-sm text-muted">{{$t('Define password')}}</p>
							</div>
							
							<div class="alert alert-danger text-center" v-if="msgerror">
								{{msgerror}}
							</div>
							<div class="form-group">
								<label for="oldpassword">{{ $t('Old Password') }}</label>
								<input type="password" id="oldpassword" v-model="oldpassword" class="form-control form-control-alt">
							</div>
							<div class="form-group">
								<label for="password">{{ $t('New Password') }}</label>
								<input type="password" id="password" v-model="password" class="form-control form-control-alt" autocomplete="new-password">
							</div>
							<div class="form-group">
								<label for="password_confirm">{{ $t('Password confirm') }}</label>
								<input type="password" id="password_confirm" v-model="password_confirm" class="form-control form-control-alt" autocomplete="new-password">
							</div>
							<div class="form-group">
								<button type="submit" class="btn btn-block btn-hero-primary" @click.prevent="save" :class="{'disabled': buttonDisabledClass}">
									<i class="fa fa-fw fa-sign-in-alt mr-1"></i> {{$t('Submit')}}
								</button>
							</div>
						
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: 'UpdatePassword',
		data: function () {
			return {
				oldpassword: '',
				password: '',
				password_confirm: '',
				busy: false,
				msgerror: null,
				refreshing: false,
				updateExists: false
			}
		},	
		methods: {
			save: async function () {
				if (this.buttonDisabledClass){
					return false;
				}
				this.msgerror = null;
				this.busy = true;
				
				let isok = await this.updatePassword({
					oldpassword: this.oldpassword,
					newpassword: this.password,
					repassword: this.password_confirm,
				});
				this.busy = false
				
				if (isok === true) {
					await this.refreshconfig();
					this.$router.replace({'name': 'home'});
				} else if (isok !== false) {
					this.msgerror = isok;
				}
			},
			...mapActions('login', [
				'updatePassword'
			]),
      		...mapActions('config',{'refreshconfig': 'refresh' }),
		},
		computed: {
			buttonDisabledClass(){
				if(!this.oldpassword) {
					return true;
				}
				if(!this.password) {
					return true;
				}
				if(!this.password_confirm) {
					return true;
				}
				if(this.oldpassword == this.password) {
					return true;
				}
				if(this.password != this.password_confirm) {
					return true;
				}
				return false;
			}
		}
	}
</script>
