import OpenReplay from '@openreplay/tracker';
import trackerVuex from '@openreplay/tracker-vuex';

const tracker = new OpenReplay({
    projectKey: 'TRV6O0hXc2wQ9Hs4tdHj',
    obscureInputEmails: false,
    obscureTextEmails: false,
    obscureInputDates: false,
    obscureTextNumbers: false,
    defaultInputMode: 0,
    obscureInputNumbers: false,
    network: {
        capturePayload: true
    }
});


const vuexPlugin = tracker.use(trackerVuex()); 

// add a name to your store, optional (will be randomly generated otherwise)
const trackervuex = vuexPlugin('MY STORE') 


export {
    tracker, 
    trackervuex
};