<template>
	<div>
        <label class="d-block">{{ label }}</label>
        <div class="row">
            <div class="col">
                <label class="btn btn-success btn-block font-weight-normal mr-2" style="cursor: pointer">
                    Prendre une photo
                    <input type="file" hidden multiple accept="image/x-png,image/jpeg,image/gif" @change="upload"
                           capture/>
                </label>
            </div>
            <div class="col">
                <label class="btn btn-info btn-block font-weight-normal mr-2" style="cursor: pointer">
                    Chercher une photo
                    <input type="file" hidden multiple accept="image/x-png,image/jpeg,image/gif" @change="upload"/>
                </label>
            </div>
        </div>

		<div class="row">
			<div class="col-6" v-for="(file, key) in getByName(responseid, name)" :key="key">
				<img :src="file.base64" class="img-thumbnail">
				<div class="btn btn-danger" @click="remove(file)"><i class="fa fa-times"></i></div>
			</div>
		</div>
	</div>
</template>
<style>
	img.img-thumbnail {
		max-height: 250px;
	}
</style>
<script>
	import {mapActions, mapGetters} from 'vuex'

	export default {
		name: "Pictures",
		props: ['name', 'uid', 'label','responseid'],
		data: function () {
			return {
				files: []
			}
		},
		beforeMount: async function () {
			await this.$store.dispatch('pictures/load', this.uid);
			console.log('beforrrr');
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('pictures', [
				'getByName'
			]),
			// mix the getters into computed with object spread operator
			...mapGetters('config', {'config': 'getConfig'}),
		},
		methods: {
			remove: function (myfile) {
			    if (confirm('Êtes vous sûr de vouloir supprimer cette photo ?')) {
				    this.$store.dispatch('pictures/delete', myfile);
                }
			},
			upload: async function (elt) {
				let self = this;
				let files = elt.target.files;
				
				files.forEach(async function (file) {
					let myBase64File = await self.convert(file);
					self.$store.dispatch('pictures/save', {base64: myBase64File, name: self.name, responseid:self.responseid})
				});
			},
			convert: async function (myFile) {
				let self = this;
				return new Promise((resolve, reject) => {
					let fileReader = new FileReader();
					if (fileReader && myFile) {
						fileReader.onload = () => {
							// resize de l'image
							const img = new Image();
							img.src = fileReader.result;
							
							img.onload = () => {
								const elem = document.createElement('canvas');
								const ctx = elem.getContext('2d');
								// img.width and img.height will contain the original dimensions
								ctx.drawImage(img, 0, 0);
								let MAX_WIDTH = self.config.camera.targetWidth; //@TODO récupérer la valeur dans la config
								let MAX_HEIGHT = self.config.camera.targetWidth;
								let width = img.width;
								let height = img.height;
								
								if (width > height) {
									if (width > MAX_WIDTH) {
										height *= MAX_WIDTH / width;
										width = MAX_WIDTH;
									}
								} else {
									if (height > MAX_HEIGHT) {
										width *= MAX_HEIGHT / height;
										height = MAX_HEIGHT;
									}
								}
								ctx.canvas.width = width;
								ctx.canvas.height = height;
								let ctx2 = ctx.canvas.getContext("2d");
								ctx2.drawImage(img, 0, 0, width, height);
								
								resolve(ctx2.canvas.toDataURL('image/jpeg'));
							}
						};
						
						fileReader.onerror = (error) => {
							reject(error);
						};
						fileReader.readAsDataURL(myFile);
					} else {
						reject('No file provided');
					}
					
				})
			}
		},
	}
</script>

<style scoped>

</style>