<template>
	<div class="d-flex justify-content-start">
		<a v-if="value" :href="url" class="btn btn-outline-dark mr-2 d-flex align-items-center">
			<i class="far fa-map-marked-alt"></i>
		</a>
		<div>
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LinkAddress",
		props: ['value', 'lat', 'lng'],
		computed: {
			url(){
				let racine = '';
				if ((navigator.platform.indexOf("iPhone") != -1) ||
					(navigator.platform.indexOf("iPad") != -1) ||
					(navigator.platform.indexOf("iPod") != -1)) {
					/* if we're on iOS, open in Apple Maps */
					racine = 'maps://maps.google.com/maps?q=';
				} else { /* else use Google */
					racine = "https://maps.google.com/maps?q=";
				}
				if (this.lat && this.lng && this.lat !="0" && this.lng != "0"){
					return racine + this.lat + ',' + this.lng;
				}
				return racine + this.value;
			}
		}
		
	}
</script>

<style scoped>

</style>