<template>
	<span>
		<i class="far fa-file-pdf text-danger" v-if="extension=='pdf'"></i>
		<i class="far fa-file-excel text-success" v-else-if="extension=='xlsx'||extension=='xls'"></i>
		<i class="far fa-file-word text-info" v-else-if="extension=='docx'||extension=='doc'"></i>
		<i class="fa fa-presentation" v-else-if="extension=='pptx'"></i>
		<i class="far fa-file-image" v-else-if="extension=='png'||extension=='jpg'||extension=='jpeg'||extension=='gif'"></i>
		<i class="fa fa-folder text-mytheme" v-else-if="extension==undefined"></i>
		<i class="far fa-file" v-else></i>
	</span>
</template>

<script>
	export default {
		props: {
			extension: {
				required: true,
			}
		}
	}
</script>

<style scoped>

</style>