const DB_NAME = 'picturedb';
const DB_VERSION = 1;
let DB;

export default {
	
	async getDb() {
		return new Promise((resolve, reject) => {
			
			if (DB) {
				return resolve(DB);
			}
			console.log('OPENING DB', DB);
			let request = window.indexedDB.open(DB_NAME, DB_VERSION);
			
			request.onerror = e => {
				console.log('Error opening db', e);
				reject('Error');
			};
			
			request.onsuccess = e => {
				DB = e.target.result;
				resolve(DB);
			};
			
			request.onupgradeneeded = e => {
				console.log('onupgradeneeded');
				let db = e.target.result;
				let objectStore = db.createObjectStore("pictures", {autoIncrement: true, keyPath: 'id'});
				objectStore.createIndex("uid", "uid", {unique: false});
				objectStore.createIndex("sync", "sync", {unique: false});
			};
		});
	},
	async deletePicture(picture) {
		
		let db = await this.getDb();
		
		return new Promise(resolve => {
			
			let trans = db.transaction(['pictures'], 'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			
			let store = trans.objectStore('pictures');
			store.delete(picture.id);
		});
	},
	async updatePicture(picture) {
		
		let db = await this.getDb();
		
		return new Promise(resolve => {
			
			let trans = db.transaction(['pictures'], 'readwrite');
			trans.oncomplete = () => {
				resolve();
			};
			
			let store = trans.objectStore('pictures');
			
			let request = store.get(picture.id);
			request.onerror = function (event) {
				// Gestion des erreurs!
			};
			request.onsuccess = function (event) {
				
				let requestUpdate = store.put(picture);
				requestUpdate.onerror = function (event) {
					// Faire quelque chose avec l’erreur
				};
				requestUpdate.onsuccess = function (event) {
					// Succès - la donnée est mise à jour !
					console.log('success update', event)
				};
			};
			
		});
	},
	async getPictures(uid) {
		
		let db = await this.getDb();
		
		return new Promise(resolve => {
			
			let trans = db.transaction(['pictures'], 'readonly');
			trans.oncomplete = () => {
				resolve(pictures);
			};
			
			let store = trans.objectStore('pictures');
			let index = store.index("uid");
			let pictures = [];
			
			index.openCursor(IDBKeyRange.only(uid)).onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					pictures.push(cursor.value)
					cursor.continue();
				}
			};
			
		});
	},
	async getPicturesBySync(syncstate) {
		
		let db = await this.getDb();
		
		return new Promise(resolve => {
			
			let trans = db.transaction(['pictures'], 'readonly');
			trans.oncomplete = () => {
				resolve(pictures);
			};
			
			let store = trans.objectStore('pictures');
			let index = store.index("sync");
			let pictures = [];
			
			index.openCursor(IDBKeyRange.only(syncstate)).onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					pictures.push(cursor.value)
					cursor.continue();
				}
			};
			
		});
	},
	async getAllPictures() {
		
		let db = await this.getDb();
		
		return new Promise(resolve => {
			
			let trans = db.transaction(['pictures'], 'readonly');
			trans.oncomplete = () => {
				console.log(pictures);
				resolve(pictures);
			};
			
			let store = trans.objectStore('pictures');
			let pictures = [];
			
			store.openCursor().onsuccess = e => {
				let cursor = e.target.result;
				if (cursor) {
					pictures.push({
						id: cursor.value.id,
						uid: cursor.value.uid,
						name: cursor.value.name,
						responseid: cursor.value.responseid,
						sync: cursor.value.sync})
					cursor.continue();
				}
			};
			
		});
	},
	
	async savePicture(picture) {
		
		let db = await this.getDb();
		
		return new Promise(resolve => {
			
			let trans = db.transaction(['pictures'], 'readwrite');
			/*trans.oncomplete = () => {
				resolve();
			};*/
			
			let store = trans.objectStore('pictures');
			let request = store.put(picture);
			request.onsuccess = function (e) {
				resolve(e.target.result);
			}
		});
		
	}
	
}