import axios from "axios";
import version from '@/services/version'

import store from '@/store/index';

const endpoint = process.env.VUE_APP_EKOAPI + '/';


export default  {

  checkerror: (error) => {
    // forbiden
    console.log('check error');
    if (error.response.status && error.response.status == 403) {
      store.dispatch('login/logout');
      return true;
    }

    return false;

  },
  get: async function({url}){

    let self = this

    const config = {
      headers: {
        'APPVERSION': version.version,
        'TOKEN' : store.getters['login/getToken']
      }
    };
    console.log('axios get', url, config);

    return new Promise(function(resolve, reject){
      axios.get(endpoint + url, config).then(response => {
        resolve(response.data)
      })
        .catch(error => {
          if (error.response) {

            self.checkerror(error);
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
          }
          console.log('Axios GET error', error);
          reject(error)
          // this.errored = true
        })
    })

  },
  post: async function({url, params}){

    let self = this

    const config = {
      headers: {
        'APPVERSION': version.version,
        'TOKEN' : store.getters['login/getToken']
      }
    };
    console.log('axios post', url, params, config);

    return new Promise(function(resolve, reject){
        axios.post(endpoint + url, params, config).then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response) {

            self.checkerror(error);
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
          }
          console.log('Axios Post error', error);
          reject(error)
          // this.errored = true
        })
    })

  },
  postFile: async function({url, params}){

    let self = this

    const config = {
      headers: {
        'APPVERSION': version.version,
        'TOKEN' : store.getters['login/getToken'],
        'Content-Type': 'multipart/form-data'
      }
    };
    console.log('axios post', url, params, config);

    return new Promise(function(resolve, reject){
        axios.post(endpoint + url, params, config).then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response) {

            self.checkerror(error);
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
          }
          console.log('Axios Post error', error);
          reject(error)
          // this.errored = true
        })
    })

  },
  getFile: async function({url, filename}){

    let self = this

    const config = {
      responseType: 'blob',
      headers: {
        'APPVERSION': version.version,
        'TOKEN' : store.getters['login/getToken']
      }
    };
    console.log('axios get', url, config);

    return new Promise(function(resolve, reject){
      axios.get(endpoint + url, config).then(response => {
        // Créer un objet URL pour le fichier blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Créer un lien de téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Ajouter le lien au document
        document.body.appendChild(link);

        // Simuler un clic sur le lien pour déclencher le téléchargement
        link.click();

        // Nettoyer l'objet URL et le lien créés
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
        .catch(error => {
          if (error.response) {

            self.checkerror(error);
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
          }
          console.log('Axios GET error', error);
          reject(error)
          // this.errored = true
        })
    })

  },

}