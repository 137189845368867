<template>
	<div id="page-container">
		<main id="main-container" class="mb-4">
			<router-view/>
		</main>
	</div>
</template>

<script>
	export default {
		name: 'LayoutLog',
	};
</script>