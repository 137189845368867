<template>
    <div>
        <b-modal v-model="modalstate" title="Définir une heure d'intervention" class="text-center" 
            cancel-title="ANNULER" cancel-variant="light" @cancel="cancel"
            ok-title="ENREGISTRER" ok-variant="success" @ok="stop" no-close-on-backdrop>
            <b-form-group class="my-2"
                                   key="askhour"
                                  :label="label" label-for="askhour">
                <b-input-group>
                    <b-form-input id="askhour" type="time" v-model="hour"
                    ></b-form-input>
                    <b-input-group-append >
                        <button class="btn btn-outline-secondary" type="button" @click.prevent="setTimeNow">Maintenant</button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-modal>
    </div>
</template>


<script type="text/javascript">
export default {
    props: {
        label: {
            type: String,
            default: 'Heure'
        },
        value: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            hour: '',
            modalstate: true,
        };
    },
    beforeMount(){
        this.hour = this.value
    },
    methods: {
        setTimeNow(){
            this.hour = (new Date()).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
        },
        stop() {
            this.$emit('input', this.hour);
        },
        cancel() {
            this.$emit('cancel');
        },

    }
}
</script>