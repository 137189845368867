<template>
	<div>
		<a v-if="email" :href="'mail:'+email" class="btn btn-outline-dark mr-2">
			<i class="fa fa-envelope"></i>
		</a>
		{{email}}
	</div>
</template>

<script>
	export default {
		name: "Email",
		props: ['value'],
		data: function () {
			return {email: ''}
		},
		mounted: function () {
			this.email = this.$options.filters.trim(this.value);
			if (this.email == '@') {
				this.email = '';
			}
		},
	}
</script>

<style scoped>

</style>