<template>
	<div class="d-flex flex-column h-100 px-2">
        <div class="flex-grow-1">
            <div class="row" v-for="question in getQuestion()" :key="'form-'+responseid+'-'+question.Id">
                <div class="col">
                    <div class="font-size-h1" v-if="question.Type=='h1'&&haveToShowQuestion(question)">{{question.Value}}</div>
                    <div class="font-size-h2" v-else-if="question.Type=='h2'&&haveToShowQuestion(question)">{{question.Value}}</div>
                    <div class="font-size-h3" v-else-if="question.Type=='h3'&&haveToShowQuestion(question)">{{question.Value}}</div>
                    <div class="font-size-h4" v-else-if="question.Type=='h4'&&haveToShowQuestion(question)">{{question.Value}}</div>
                    <div class="font-size-h5" v-else-if="question.Type=='h5'&&haveToShowQuestion(question)">{{question.Value}}</div>
                    <div class="font-size-h6" v-else-if="question.Type=='h6'&&haveToShowQuestion(question)">{{question.Value}}</div>
                    <p v-else-if="question.Type=='paragraph'&&haveToShowQuestion(question)" v-html="$options.filters.nl2br(question.Value)"></p>

                    <pictures :key="'picture-'+responseid+'-'+question.Id" v-else-if="question.Type=='picture'&&haveToShowQuestion(question)" :uid="eventid"
                              :responseid="responseid"
                              :name="question.Name" :label="question.Label" class="my-2">PICTURESS</pictures>

                    <div class="row mb-3 mt-2" v-else-if="question.Type=='signature'&&haveToShowQuestion(question)" :key="'sign-'+question.Id">
                        <div class="col">
                            <label class="d-block" >{{question.Label}}</label>
                            <signature :question="question" v-model="response[question.Name]"></signature>
                            <div v-if="errors[question.Name]" class="text-danger">{{errors[question.Name]}}</div>
                        </div>
                    </div>


                    <b-form-group v-else-if="(question.Type=='text'||question.Type=='email'||question.Type=='time'||question.Type=='date')&&haveToShowQuestion(question)" class="my-2"
                                   :key="'input-'+question.Id"
                                  :class="{required:question.Required}"
                                  :label="question.Label" :label-for="question.Name">
                        <b-input-group>
                            <b-form-input :id="question.Name" :type="question.Type" :class="{'is-invalid':errors[question.Name]}"
                                          :required="question.Required" v-model="response[question.Name]"
                            ></b-form-input>
                            <b-input-group-append v-if="question.InputVoice">
                                <speech-text @text="onSpeech($event, question.Name)"></speech-text>
                            </b-input-group-append>
                            <b-input-group-append v-if="question.Type=='time'">
                                <button class="btn btn-outline-secondary" type="button" @click.prevent="setTimeNow(question.Name)">Maintenant</button>
                            </b-input-group-append>
                        </b-input-group>
                        <div v-if="errors[question.Name]" class="text-danger">{{ errors[question.Name] }}</div>
                    </b-form-group>

                    <b-form-group v-else-if="question.Type=='select'&&haveToShowQuestion(question)" class="my-2" :class="{required:question.Required}"
                                   :key="'select-'+question.Id"
                                  :label="question.Label" :label-for="question.Name">
                        <b-form-select :name="question.Name" :id="question.Name" v-model="response[question.Name]" :class="{'is-invalid':errors[question.Name]}">
                            <option v-for="opt in question.Option" :value="opt.key">{{opt.value}}</option>
                        </b-form-select>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </b-form-group>

                    <b-form-group v-else-if="question.Type=='rating'&&haveToShowQuestion(question)" class="my-2" :class="{required:question.Required}"
                                   :key="'rating-'+question.Id"
                                  :label="question.Label" :label-for="question.Name">
                        <b-form-rating :name="question.Name" :id="question.Name" v-model="response[question.Name]"  no-border size="lg" show-clear
                        :class="{'is-invalid':errors[question.Name]}" :stars="question.Option.max"></b-form-rating>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </b-form-group>


                    <div class="form-group my-2" :class="{required:question.Required}" :key="'textarea-'+question.Id"
                         v-else-if="question.Type=='textarea'&&haveToShowQuestion(question)">
                        <speech-text v-if="question.InputVoice" class="float-right" @text="onSpeech($event, question.Name)" type="onlyicon">
                        </speech-text>
                        <label :for="question.Name">{{question.Label}}</label>

                        <textarea class="form-control" :required="question.Required" v-model="response[question.Name]"
                                  :class="{'is-invalid':errors[question.Name]}" :name="question.Name" :id="question.Name"></textarea>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </div>

                    <div class="form-group my-2" :class="{required:question.Required}" :key="'multi-'+question.Id"
                         v-else-if="question.Type=='multiselect'&&haveToShowQuestion(question)">
                        <label :for="question.Name">{{question.Label}}</label>
                        <select :name="question.Name" :id="question.Name" class="form-control" :required="question.Required"
                                 :class="{'is-invalid':errors[question.Name]}" multiple v-model="response[question.Name]">
                            <option v-for="opt in question.Option" :value="opt.key">{{opt.value}}</option>
                        </select>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </div>
                    <b-form-group :key="'checkbox-'+question.Id" v-else-if="question.Type=='checkbox'&&haveToShowQuestion(question)" class="my-2">
                        <b-form-checkbox  v-model="response[question.Name]" switch
                                          :class="{'is-invalid':errors[question.Name]}" class="custom-control-lg">
                            {{question.Label}}
                        </b-form-checkbox>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </b-form-group>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col">
                    <div class="btn btn-block btn-primary" @click="nextPage()">
                        {{$t(page==totalPages ? 'Submit' : 'Next page')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center font-size-xs text-muted" v-if="totalPages>1">
            <template v-for="i in (page-1)">
                <i class="far fa-circle mr-1"></i>
            </template>
            <i class="fa fa-circle mr-1"></i>
            <template v-if="page != totalPages" v-for="i in (totalPages-page)">
                <i class="far fa-circle mr-1"></i>
            </template>
        </div>
        <br>
	</div>
</template>


<script>

	// @ is an alias to /src
	import {mapActions, mapGetters} from 'vuex'
	import Pictures from '@/components/Pictures.vue';
	import signature from '@/components/signature.vue';
	import SpeechText from '@/components/SpeechText.vue';

	export default {
		name: 'event',
		props: {
		    'responseid':{},
			'eventid': String,
			'page': {
				default: 1
			}
		},
		data: function () {
			return {
				form: [],
				errors: {},
				response: {},
				totalPages: 1,
			}
		},
		components: {signature, Pictures, SpeechText},
		created: async function () {
			this.response = this.getResponses({uid: this.eventid, responseid: this.responseid})

			let form = this.getform(this.response.action.form_id);
			
			if (form === null) {
				await this.$store.dispatch('forms/refresh', [this.action.form_id]);
				form = this.getform(this.action.form_id);
			}
			this.form = form;
			
			this.totalPages = Math.max.apply(Math, this.form.map(function (o) {
				return Number(o.Page);
			}));

			this.getGeoloc();
		},
		updated: async function () {
			let self = this;
            this.form.map(item => {
                if (item.Page == self.page && item.Type == 'signature') {
                    if(self.$refs[item.Name]) {
                        self.$refs[item.Name][0].fromDataURL(self.response[item.Name]);
                    }
                }
                if (item.Type == 'multiselect'){
                    if(self.response[item.Name] == undefined){
                        self.response[item.Name] = [];
                    }
                }
            });
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('forms', [
				'getform'
			]),
			...mapGetters('interventions', [
				'getResponses'
			]),
			...mapGetters('pictures', [
				'getByName'
			]),
			...mapGetters('config', {'config': 'getConfig'}),
		},
		methods: {
			...mapActions('interventions', ['setResponse', 'finishIntervention']),
            onSpeech(event, questionname){
			    console.log('onSpeech', event);

			    if (undefined == this.response[questionname]) {
                    let resp = {...this.response};

                    resp[questionname] = event;
                    this.response = resp;
                } else {
			        this.response[questionname] += " "+event;
                }
            },
            setTimeNow(questionname){
                let now = (new Date()).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
			    if (undefined == this.response[questionname]) {
                    let resp = {...this.response};

                    resp[questionname] = now;
                    this.response = resp;
                } else {
			        this.response[questionname] = now;
                }
            },
			getQuestion: function () {
				let self = this;
				return this.form.filter(item => {
					return item.Page == self.page;
				});
			},
			checkEmail: function(email) {
				let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  				return re.test(email);
			},
            haveToShowQuestion(question) {
                if (!question.Conditionnel || !question.Conditionnel.name) {
                    return true;
                }

                // une condition d'affichage est à mettre en place*
                if (Array.isArray(question.Conditionnel.value)) {
                    // un tableau de valeur, on test la présence d'au moins une valeur souhaitée
                    if (Array.isArray(this.response[question.Conditionnel.name])) {
                        // multiselect
                        console.log('multiselect',question.Conditionnel.value,  this.response[question.Conditionnel.name]);

                        for(let i=0; i< this.response[question.Conditionnel.name].length;i++) {
                            if (_.filter(question.Conditionnel.value, {key: this.response[question.Conditionnel.name][i]}).length >0) {
                                return true;
                            }
                        }
                        return false;
                    } else {
                        // select
                        return _.filter(question.Conditionnel.value, {key: this.response[question.Conditionnel.name]}).length >0;
                    }
                } else {
                    // test d'une présence de valeur ou non uniquement
                    return question.Conditionnel.value ? this.response[question.Conditionnel.name] : !this.response[question.Conditionnel.name]
                }

            },
            prevPage: function(){
                this.setResponse({uid: this.eventid, responseid:this.responseid, data: this.response});
                this.finishIntervention({uid: this.eventid});
			    if (this.page == 1) {
					// retourne sur la fiche intervention
					this.$router.push({name: 'event', params: {eventid: this.eventid}});
				} else {
			        this.$router.push({
						'name': 'resultMission',
						'params': {eventid: this.eventid, 'action': self.action, 'page': Number(this.page) - 1}
					});
                }
            },
			nextPage: function () {
			    Dashmix.layout('header_loader_on');
				let self = this;
				self.errors = {};
				// enregistrement des signatures s'il y en a
				this.form.map(item => {
					if (item.Page == self.page) {
					    if (!self.haveToShowQuestion(item)) {
					        // le champ n'est pas affiché donc il doit etre vidé et non vérifié
                            delete self.response[item.Name];
                            return;
                        }

                        if (item.Type == 'picture'){
                            // on place les id de photo dans les réponses pour pouvoir compter
                            self.response[item.Name] = self.getByName(self.responseid, item.Name).map(p => p.id);
                        } else if (item.Type == 'signature') {
						    if(item.Required && !self.response[item.Name]) {
						        self.errors[item.Name] = self.$t('Required input');
                            }
						} else if (item.Type == 'email' && self.response[item.Name]) {
							// check de l'email s'il est complété
							if (!self.checkEmail(self.response[item.Name])) {
								self.errors[item.Name] = self.$t('Invalid email');
							}
						}
						if (item.Required && item.Type =='multiselect' && self.response[item.Name].length ==0){
							self.errors[item.Name] = self.$t('Required input');
                        } else if(item.Required && !self.response[item.Name]){
							self.errors[item.Name] = self.$t('Required input');
						}
					}
				});

				if (Object.keys(self.errors).length > 0) {
				    Dashmix.layout('header_loader_off');
					return ;
				}

				if (self.page == self.totalPages) {
					self.response.finished = true;
					self.setResponse({uid: self.eventid, responseid:self.responseid, data: self.response});
					self.finishIntervention({uid: self.eventid});
					// retourne sur la fiche intervention
					self.$router.push({name: 'event', params: {eventid: self.eventid}});
				} else {
					self.setResponse({uid: self.eventid, responseid:self.responseid, data: self.response});
					self.finishIntervention({uid: self.eventid});
					self.$router.push({
						'name': 'resultMission',
						'params': {eventid: self.eventid, 'action': self.action, 'page': Number(self.page) + 1}
					});
				}
			},
			getGeoloc: function(){
				let self = this;
				if (navigator.geolocation && 'UT-A7-C62-Q8749' != self.config.user.uid) {
					navigator.geolocation.getCurrentPosition(function (position) {
						self.response.geoloc.latitude = position.coords.latitude;
						self.response.geoloc.longitude = position.coords.longitude;
						self.response.geoloc.accuracy = position.coords.accuracy;
					});
				}
				console.log(self.response.geoloc);
			}
		}
	}
</script>
