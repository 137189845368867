import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(BootstrapVue)
Vue.use(Vue2TouchEvents, {
	swipeTolerance: 50,
});

import * as firebase from "firebase/app";
import "firebase/messaging";

import App from './App.vue'
import router from './router'
import store from './store'
import * as moment from 'moment';
import './registerServiceWorker'

import './assets/js/dashmix/app';
import './styles/main.scss';
import './styles/dashmix/themes/xwork.scss';
import i18n from './i18n'


const FCMconfig = {
	apiKey: "AIzaSyAOfqDf4WircSmU71oiK3W8cOcwCZj_Hnc",
	authDomain: "eko-intervention.firebaseapp.com",
	databaseURL: "https://eko-intervention.firebaseio.com",
	projectId: "eko-intervention",
	storageBucket: "eko-intervention.appspot.com",
	messagingSenderId: "886741177350",
	appId: "1:886741177350:web:1c652e608b20f3dff6df87"
}

firebase.initializeApp(FCMconfig);

Vue.prototype.$messaging = null;

Vue.config.productionTip = false
Vue.filter('formatTime', function (value) {
	if (value) {
		return moment(String(value)).format('HH:mm')
	}
});
Vue.filter('formatDateTime', function (value) {
	if (value) {
		return moment(String(value)).format('DD/MM/YYYY HH:mm')
	}
});

Vue.filter('formatDate', function (value) {
	if (value) {
		return moment(String(value)).format('DD/MM/YYYY')
	}
});
Vue.filter('trim', function (value) {
	return value.trim();
});

Vue.filter('nl2br', function (value) {
	return value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2');
});

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')


