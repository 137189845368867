import idb from '@/services/idb';
import api from '@/services/api'

const SYNC_RUNNING = 2;
const SYNC_FINISH = 1;
const SYNC_WAITING = 0;
const base64ToBlob = (dataurl) => {
	const arr = dataurl.split(',');
	const mime = arr[0].match(/:(.*?);/)[1]
	const sliceSize = 1024;
	const byteChars = window.atob(arr[1]);
	const byteArrays = [];
	
	for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
		let slice = byteChars.slice(offset, offset + sliceSize);
		
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		
		const byteArray = new Uint8Array(byteNumbers);
		
		byteArrays.push(byteArray);
	}
	
	return new Blob(byteArrays, {type: mime});
}

const getFilename = (dataUrl) => {
	const arr = dataUrl.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	
	return Math.round(+new Date() / 1000) + '.' + mime.split('/').pop();
}

const store = {
	namespaced: true,
	state: {
		pictures: [],
		uid: null
	},
	mutations: {
		remove(state, picture) {
			state.pictures.splice(state.pictures.indexOf(picture), 1);
		},
		setpictures(state, pics) {
			state.pictures = pics;
		},
		addpicture(state, pic) {
			state.pictures.push(pic);
		},
		reset(state) {
			state.pictures = [];
			state.uid = null;
			
		},
		setuid(state, uid) {
			state.uid = uid;
		},
	},
	
	actions: {
		async delete(context, picture) {
			await idb.deletePicture(picture);
			context.commit('remove', picture);
		},
		async load(context, uid) {
			
			if (context.state.uid != uid) {
				context.commit('reset');
				
				let pictures = await idb.getPictures(uid);
				context.commit('setpictures', pictures);
				context.commit('setuid', uid);
				console.log('fin load');
			}
		},
		async loadall(context) {
			context.commit('reset');
			let pictures = await idb.getAllPictures();
			context.commit('setpictures', pictures);
			context.commit('setuid', null);
			
		},
		
		async removeSynced(context, evtids){
			console.log('removeSynced');
			let pictures = await idb.getAllPictures();
			if (pictures.length){
				pictures.forEach((pic) => {
					if (pic.sync == SYNC_FINISH && evtids.indexOf(pic.uid) === -1) {
						idb.deletePicture(pic);
					}
				})
			}
		},
		
		async save(context, picture) {
			picture.index = context.state.pictures ? context.state.pictures.length + 1 : 0;
			picture.uid = context.state.uid;
			picture.sync = SYNC_WAITING;
			picture.id = await idb.savePicture(picture);
			context.commit('addpicture', picture);
		},
		
		async sync() {
			
			let tosyncs = await idb.getPicturesBySync(SYNC_WAITING);
			console.log('tosyncs', tosyncs);
			tosyncs.forEach(async function (picture) {
				
				try {
					
					console.log('update picture', picture);
					picture.sync = SYNC_RUNNING
					await idb.updatePicture(picture);
					
					let file = base64ToBlob(picture.base64);
					
					const data = new FormData();
  					data.append('mission-picture[]', file, getFilename(picture.base64));
  					data.append('responseid', picture.responseid);
					
					let call = await api.postFile({
						url: 'intervention/v3/missions/' + picture.uid + '/pictures/save/' + picture.index + '/' + picture.name,
						params: data
					});
					
					picture.sync = SYNC_FINISH
					await idb.updatePicture(picture);
					
					console.log('end update picture', picture);
					
				} catch (e) {
					console.log('Error sync picture', e, picture);
					picture.sync = SYNC_WAITING
					await idb.updatePicture(picture);
				}
				
			});
			
		}
		
		
	},
	getters: {
		getByName: (state) => (responseid, name) => {
			// console.log('getByName',responseid, name);
			
			return state.pictures.filter((pic) => {
				return pic.responseid == responseid && pic.name == name;
			});
		},
		getAll: (state) => () => {
			// console.log('getAll', state.pictures);
			return state.pictures;
		}
		
		
	},
	
}

export default store