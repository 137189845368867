<template>
    <div>
        <img v-if="value" :src="value" @click.prevent="edit" height="200px" width="100%" style="border:1px solid">
        <div v-else class="w-100 justify-content-center d-flex align-items-center" style="height:200px;border:1px solid"
             @click.prevent="edit">
            <div class="text-center">
                <i class="fal fa-signature fa-3x"></i>
                <br>
                <div>Taper pour signer</div>
            </div>
        </div>
        <small class="form-text text-muted">Cliquez sur le cadre pour accéder à la signature</small>
        <div class="block block-mode-fullscreen" v-if="displaySignature">
            <div class="block-content h-100 d-flex justify-content-center flex-column">
                <div class="d-flex flex-column mt-auto">
                    <VueSignaturePad height="200px" :key="question.Id" :name="question.Name"
                                     :options="signaturePadOptions"
                                     :ref="question.Name" class="border border-2x"/>
                </div>
                <div class="d-flex mt-auto mb-2 justify-content-between mt-2">
                    <div class="btn btn-lg btn-danger" @click="clearSignature">
                        {{ $t('Erase') }}
                    </div>
                    <div class="btn btn-lg btn-primary" @click="saveSignature()">
                        {{ $t('Close') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSignaturePad from 'vue-signature-pad';

export default {
    name: "signature",
    components: {VueSignaturePad},
    props: {
        question: {
            required: true,
            type: Object
        },
        value: String
    },
    data: function () {
        return {
            displaySignature: false,
            signaturePadOptions: { // Check out https://github.com/szimek/signature_pad
                'minWidth': 2,
                'height': 200,
            },
        }
    },
    methods: {
        saveSignature: function () {
            if (this.$refs[this.question.Name].isEmpty()) {
                this.$emit('input', '');
            } else {
                let sign = this.$refs[this.question.Name].saveSignature();
                this.$emit('input', sign.data);
            }
            this.displaySignature = false;
        },
        clearSignature: function () {
            this.$refs[this.question.Name].clearSignature();
        },
        edit() {
            let self = this;
            this.displaySignature = true;
            this.$nextTick(() => {
                self.$refs[self.question.Name].fromDataURL(self.value);
            })
        }
    },
}
</script>