<template>
    <div>
        <b-button v-if="type=='button'" variant="outline-secondary" @click.prevent="modalstate=true;startSpeechToTxt()"
                  style="
                border-top-left-radius: 0;border-bottom-left-radius: 0;">
            <i class="far fa-microphone"></i>
        </b-button>
        <i v-if="type=='onlyicon'" class="far fa-microphone" @click.prevent="modalstate=true;startSpeechToTxt()"></i>
        <b-modal v-model="modalstate" class="text-center" ok-only ok-title="FERMER" @cancel="cancel" @ok="stop"
                 hide-header no-close-on-backdrop>
            <div class="text-center">
                <i class="far fa-microphone fa-4x fa-border border-info bg-info rounded-circle text-white"
                   :class="{'speech': audio}" @click="startSpeechToTxt"></i>

                <div style="min-height:50px;" class="mt-3">
                    <span id="final_sentence">{{ final_sentence }}</span>
                    <span id="interim_sentence" style="color:grey">{{ interim_sentence }}</span>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<style>
@keyframes speech {
    0% {
        background-color: #d1e5f7;
        box-shadow: 0 0 5px #d1e5f7;
        color: #164f86;
    }
    50% {
        background-color: #164f86;
        box-shadow: 0 0 20px #164f86;
        color: #d1e5f7;
    }
    100% {
        background-color: #d1e5f7;
        box-shadow: 0 0 5px #d1e5f7;
        color: #164f86;
    }
}

.speech {
    animation: speech 1800ms infinite;
}
</style>

<script type="text/javascript">
export default {
    props: {
        type: {
            type: String,
            default: 'button'
        }
    },
    data() {
        return {
            _recognition: null,
            audio: false,
            speech: false,
            modalstate: false,
            final_sentence: '',
            interim_sentence: '',
        };
    },
    methods: {
        cancel() {
            if (this._recognition !== null) {
                this._recognition.stop();
                this._recognition = null;
            }
            this.final_sentence = '';
            this.interim_sentence = '';
        },
        stop() {
            // console.log('stop', this._recognition);
            if (this._recognition !== null) {
                this._recognition.stop();
                this._recognition = null;
            }
            this.$emit('text', this.final_sentence);
            this.final_sentence = '';
            this.interim_sentence = '';
            this.modalstate = false;
        },
        startSpeechToTxt() {
            let self = this;
            /* Initialise la reconnaissance vocale */
            let SpeechRecognition = SpeechRecognition || webkitSpeechRecognition
            this._recognition = new SpeechRecognition();

            /* Français de France */
            this._recognition.lang = 'fr-FR';
            this._recognition.interimResults = true;
            this._recognition.continuous = false; // écoute vraiment longtemps

            /* Lorsque une voix est détectée */
            this._recognition.onresult = function (event) {
                // console.log('onresult', event);
                /* récupère le mot ou la phrase */
                let final = "";
                let interim = "";
                for (let i = 0; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        final += event.results[i][0].transcript;
                    } else {
                        interim += event.results[i][0].transcript;
                    }
                }
                self.final_sentence = final;
                self.interim_sentence = interim;
            }

            this._recognition.onerror = function (event) {
                // console.log('Erreur : ' + event.error);
                self.speech = false;
                self.audio = false;
            }

            this._recognition.onspeechstart = function (event) {
                // console.log('onspeechstart', event);
                self.speech = true;
            }
            this._recognition.onspeechend = function (event) {
                // console.log('onspeechend', event);
                self.speech = false;
            }
            this._recognition.onaudiostart = function (event) {
                // console.log('onaudiostart', event);
                self.audio = true;
            }
            this._recognition.onaudioend = function (event) {
                // console.log('onaudioend', event);
                self.audio = false;
            }
            this._recognition.onnomatch = function (event) {
                // console.log('onnomatch', event);
            }
            this._recognition.onend = function (event) {
                // console.log('onend', event);

                self.stop();
            }
            this._recognition.start();
        },

    }
}
</script>