<template>
	<div>
        <div v-if="!loaded" class="mx-2">
			<p>{{ $t('L\'historique est disponible uniquement si vous êtes connecté à internet') }}</p>
			<a href="#" @click.prevent="load" class="btn btn-block btn-lg btn-light">
				<b-spinner v-if="loading" type="border" small></b-spinner> 
				{{ $t('Charger l\'historique') }}
				</a>
		</div>
		<div v-if="!detailIntervention">
			<div class="alert alert-info" v-if="loaded&& dates.length == 0">Aucun historique</div>
			<ul class="list-group" v-for="date in dates">
				<div class="bg-light">
					<div class="d-flex align-items-end justify-content-center my-2">
						<div class="font-size-h1 font-weight-bold text-info mr-2">
							{{getDay(date)}}
						</div>
						<div class="font-weight-bold font-size-sm text-dark-50">
							{{getDayOfWeek(date)}} <br>
							{{getMonth(date)}} {{ getYear(date) }}
						</div>
					</div>
				</div>
				<template v-for="event in interventions[date]">
					<li @click="clickEvent(event)"
						:key="event.uid" class="list-group-item"
						:style="{'border-left-color': event.color}">
						<div class="d-flex justify-content-between">
							<div>
								<div> {{event.typelibelle}}</div>

								<div class="font-size-sm font-weight-bold text-muted">
									{{event.title}}
								</div>
							</div>
						</div>
						<div class="font-size-sm d-flex justify-content-end mt-2">
							{{event.state}}
						</div>
					</li>
				</template>
			
			
			</ul>
		</div>
		<template v-else >
			<a href="#" @click.prevent="detailIntervention = ''" class="btn btn-block btn-light">{{ $t('Retour') }}</a>
			<div v-html="detailIntervention"></div>
			<a href="#" @click.prevent="detailIntervention = ''" class="btn btn-block btn-light">{{ $t('Retour') }}</a>
		</template>
	</div>
</template>

<script>
	import api from '@/services/api'
	import moment from 'moment';
	moment.locale('fr');

	export default {
		name: "InterventionHistory",
		props: [ 'uid'],
		data: function () {
			return {
				loaded: false,
				loading: false,
				interventions: null,
				detailIntervention: '',
			}
		},
		computed: {
			dates(){
				if (this.interventions === null){
					return [];
				}
				return _.reverse(_.sortBy(_.keys(this.interventions)));
			}
		},
		methods: {
			load(){
				let self = this;
				self.loading = true;

				
				api.get({ url: 'intervention/v3/missions/'+self.uid+'/history'}).then((res) => {
					self.loading = false;
					self.loaded = true;
					self.interventions = res.response.interventions;
				}).catch((err) => {
					alert('Impossible de récupérer l\'historique');
					self.loading = false;
					self.loaded = false;
				})
			},
			getDay(date){
				return moment(date).format('D');
			},
			getDayOfWeek(date){
				return moment(date).format('dddd');
			},
			getMonth(date){
				return moment(date).format('MMM');
			},
			getYear(date){
				return moment(date).format('YYYY');
			},
			getEnd(event) {
				return moment(event.start).add(event.duration, 'minutes');
			},
			clickEvent(event){
				let self =this;
				api.get({url: 'intervention/v3/missions/'+event.uid+ '/detail'}).then((res) => {
					if (!res.response.html) {
						alert('Aucun rapport pour cette intervention');
					} else {
						self.detailIntervention = res.response.html;
					}
				}).catch((err) => {
					alert('Impossible de récupérer l\'historique');
				})
			}
		},
	}
</script>


<style>
	img.img-thumbnail {
		max-height: 250px;
	}
</style>