<template>
	<div class="h-100">

		<b-tabs nav-class="nav-tabs-alt" fill alt content-class="mt-3" v-if="event">
			<b-tab :title="$t('Inter.')" active>
				<div class=" px-2">
					<div class="font-size-h3 text-info text-center">{{event.client.name}}<template v-if="event.client.addressname"> - {{event.client.addressname}}</template></div>
					<p class="mb-0" v-if="event.typelibelle">{{event.typelibelle}}</p>
					<p v-if="event.title" class="font-size-sm font-weight-bold text-muted" v-html="$options.filters.nl2br(event.title)"></p>
					<p v-if="event.comment" v-html="$options.filters.nl2br(event.comment)"></p>
					
					<div class="row" v-if="event.client.address">
						<div class="col py-1">
							<link-address :lat="event.lat" :lng="event.lng"
									v-model='event.client.address+" "+event.client.city'>
								{{event.client.address}}<br>
								{{event.client.zipcode}} {{event.client.city}}
							</link-address>
						</div>
					</div>
					<div class="row" v-if="event.client.phone">
						<div class="col py-1">
							<tel v-model="event.client.phone"></tel>
						</div>
					</div>
					<div class="row" v-if="event.client.mobile">
						<div class="col py-1">
							<tel v-model="event.client.mobile"></tel>
						</div>
					</div>
					<div class="row">
						<div class="col py-1">
							<email v-model="event.client.email"></email>
						</div>
					</div>

					<div v-if="event.contacts" v-for="c in event.contacts">
						<div class="font-size-h5 mt-2 font-weight-bold">{{c.name}}<span v-if="c.fonction" class="ml-2 font-size-sm">{{ c.fonction }}</span></div>
						<div class="row" v-if="c.phone">
							<div class="col py-1">
								<tel v-model="c.phone"></tel>
							</div>
						</div>
						<div class="row" v-if="c.mobile">
							<div class="col py-1">
								<tel v-model="c.mobile"></tel>
							</div>
						</div>
					</div>
				</div>
				
				
				<!-- <div class="row text-center font-size-sm my-2" v-if="event&&!intervention.started_at">
					<div class="col-4">
						<i class="fal fa-2x fa-stopwatch"></i><br>{{event.duration}}
					</div>
					<div class="col-4">
						<i class="fal fa-2x fa-calendar-alt"></i><br>{{event.start | formatDate}}
					</div>
					<div class="col-4">
						<i class="fal fa-2x fa-clock"></i><br>{{event.start | formatTime}}
					</div>
				</div> -->

				<div class="d-flex text-center my-0 border-top border-bottom py-4" v-if="askStartEnd">
					<div class="col-12" @click.prevent="setStart" v-if="!intervention.started_at">
						<i class="far fa-2x fa-play-circle text-success"></i><br>{{$t('Démarrer l\'intervention') }}
					</div>
					<div class="col-6" @click.prevent="editStart" v-if="intervention.started_at">
						<i class="fal fa-2x fa-play-circle"></i><br>{{ intervention.started_at }}
					</div>
					<div class="col-6" v-if="intervention.started_at&&intervention.ended_at" @click.prevent="editEnd">
						<i class="fal fa-2x fa-stop-circle"></i><br>{{ intervention.ended_at }}
					</div>
					<div class="col-6" @click.prevent="setEnd" v-if="!intervention.ended_at&&intervention.started_at">
						<i class="far fa-2x fa-stop-circle text-danger"></i><br>{{$t('Arrêter l\'intervention') }}
					</div>
				</div>

		<!--		Liste des responses déjà présentes-->
				<div class="mt-4 mx-1" v-if="Object.keys(intervention.responses).length">
					<div class="h5 mb-1">Formulaire(s) déjà ajouté(s)</div>
					<div class="d-flex flex-wrap align-items-stretch">
						<div v-for="(reponses,k) in intervention.responses" :key="k" class="col-6 px-1 pb-1 mt-2">
								<a class="btn h-100 btn-lg ribbon ribbon-left ribbon-modern btn-block h-100 text-capitalize text-wrap text-break d-flex flex-column justify-content-center py-5" @click.prevent="continueReponse(k)"
								:class="{'btn-outline-success ribbon-success':reponses.finished, 'btn-outline-warning ribbon-warning':!reponses.finished}" @contextmenu.prevent="removeResponses(k)">
									<div class="font-size-base">
										{{ reponses.action.name }}
										<span class="ribbon-box font-size-xs" v-if="reponses.finished"><i class="fa fa-check-circle"></i> {{ $t('terminé') }}</span>
										<span class="ribbon-box font-size-xs" v-if="!reponses.finished"><i class="fa fa-exclamation-circle"></i> {{ $t('en cours') }}</span>
									</div>
								</a>
						</div>
						<div class="col-6 px-1 pb-1 mt-2">
							<a href="#" class="btn h-100 btn-outline-secondary btn-lg btn-block h-100 text-wrap text-break d-flex flex-column justify-content-center py-4" 
							style="border-style:dashed"
							@click.prevent="showActions = true">
								<div class="font-size-base">
									{{$t('Ajouter un formulaire')}}
								</div>
							</a>
						</div>
					</div>
				</div>
				<template v-if="event.actions.length&&canShowActions">
					<div v-if="showActions" class="mt-4 mw-100">
						<div class="h5 mb-1 mx-1">Choisissez un formulaire</div>
						<div class="d-flex flex-wrap align-items-stretch">
							<div class="col-6 px-1 pb-1" v-for="(action,k) in event.actions" :key="`buttons-`+k">
								<a href="#" class="btn btn-lg btn-block h-100 btn-outline-info text-capitalize text-wrap text-break d-flex flex-column justify-content-center"
								@click.prevent="createResponseAndRoute(action)">
								<div class="font-size-base">{{action.name}}</div>
								</a>

							</div>
						</div>
					</div>
				</template>

				<template v-if="event.buttons.length>0">
					<div v-if="hasResponseNotFinished" class="alert alert-warning mt-4">Un rapport n'est pas terminé, changement d'état impossible</div>
					<template v-else>
						<div class="h5 mt-4 mb-1 mx-1">J'ai fini l'intervention et je souhaite changer son état</div>
						<div class="d-flex flex-wrap align-items-stretch">
							<div class="col-6 px-1 pb-1" v-for="(button,k) in event.buttons" :key="`buttons-actions-`+k">
								<a href="#"  @click.prevent="closeIntervention(button.etat_id)"
								class="btn btn-block btn-lg h-100 text-wrap text-break d-flex flex-column justify-content-center" :class="`btn-`+button.color">
									<div class="font-size-base">{{button.name}} <i class="fa fa-2x fa-check-circle" v-if="intervention.toetatid && button.etat_id == intervention.toetatid"></i></div>
								</a>
							</div>
						</div>
					</template>
				</template>
				<template v-else>
					<!-- PAS DE BOUTON CONFIGURE : ON EN MET UN PAR DEFAUT -->
					<div class="d-flex flex-wrap align-items-stretch">
						<div class="col-12 px-1 pb-1">
							<a href="#"  @click.prevent="markAsFinished()"
							class="btn btn-block btn-lg h-100 text-wrap text-break d-flex flex-column justify-content-center btn-success">
								<div class="font-size-base">{{$t('Marquer comme terminé')}}</div>
							</a>
						</div>
					</div>
				</template>
				<ask-hour v-if="labelaskhour" :label="labelaskhour" @cancel="labelaskhour='';" @input="setEditingHour($event)" :value="valueHour"></ask-hour>
				<br>
			</b-tab>
			<b-tab :title="$t('Historique')">
				<intervention-history :key="`history-`+eventid" :uid="eventid"></intervention-history>
			</b-tab>
			<b-tab :title="$t('Fichiers')" v-if="event.fichiers&&event.fichiers.length">
				<!-- Liste les fichiers dispo sur le RDV -->
				<ul class="list-group">
					<a href="#" v-for="fic in event.fichiers" :key="`fichier-`+eventid+`-`+fic.id" class="list-group-item list-group-item-action d-flex"
						@click.prevent="downloadFile(fic.id, fic.libelle)">
						<div><display-extension :extension="fic.extension"></display-extension></div>
						<div class="ml-3 flex-fill">{{ fic.libelle }}</div>
					</a>
				</ul>
			</b-tab>
			<b-tab :title="$t('Parc')" v-if="event.parc.name || event.contrat.name">
				<div class="block " v-if="event.parc.name">
					<div class="block-content block-content-full py-2">
						<div class="font-size-h4">{{event.parc.name}}</div>
						<p class="mb-0" v-if="event.parc.type">{{event.parc.type}}</p>
						<p class="mb-0" v-if="event.parc.marque||event.parc.modele">{{event.parc.marque}} {{event.parc.modele}}</p>
						<p class="mb-0" v-if="event.parc.nserie">{{event.parc.nserie}}</p>
						<p class="mb-0" v-if="event.parc.date_mes"><span class="h6">MES</span> : {{event.parc.date_mes| formatDate}}</p>
						
						<p class="mb-0" v-if="event.parc.others.length" v-for="(cp,k) in event.parc.others" :key="`parc-cp-`+k">
							<span class="h6">{{cp.libelle}}</span> : <span v-html="cp.valeur"></span>
						</p>
					</div>
				</div>
				<div class="block " v-if="event&&event.contrat.name">
					<div class="block-content block-content-full py-2">
						<div class="font-size-h4">{{event.contrat.name}}</div>
						<p class="mb-0" v-if="event.contrat.type">{{event.contrat.type}}</p>				
						<p class="mb-0" v-if="event.contrat.others.length" v-for="(cp,k) in event.contrat.others" :key="`contrat-cp-`+k">
							<span class="h6">{{cp.libelle}}</span> : <span v-html="cp.valeur"></span>
						</p>
					</div>
				</div>
			</b-tab>
		</b-tabs>
		

	</div>
</template>


<script>
	
	// @ is an alias to /src
	import moment from 'moment';
	moment.locale('fr');
	import {mapActions, mapGetters} from 'vuex'
	import Tel from '@/components/Tel.vue';
	import Email from '@/components/Email.vue';
	import LinkAddress from '@/components/LinkAddress.vue';
	import AskHour from '@/components/AskHour.vue';
	import DisplayExtension from '@/components/DisplayExtension.vue';
	import InterventionHistory from '@/components/InterventionHistory.vue';

	import api from '@/services/api'
	
	export default {
		name: 'event',
		props: ['eventid'],
		components: {
			Tel, Email, LinkAddress, AskHour, DisplayExtension, InterventionHistory
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('events', [
				'getEvent'
			]),
			...mapGetters('config', {'config': 'getConfig'}),
			...mapGetters('interventions', [
				'getIntervention'
			]),
			canShowActions(){
				return !this.askStartEnd || this.intervention.started_at;
			},
			askStartEnd(){
				// est-ce que le tec doit spécifier quand il démarre et termine l'intervention
				return this.config.haveToAskStartEnd && this.event.actions.length;
			},
			valueHour(){
				if (this.labelaskhour =='Fin') {
					return this.intervention.ended_at;
				}else {
					return this.intervention.started_at;
				}
			},
			hasResponseNotFinished(){
				return Object.keys(this.intervention.responses).length > 0 && _.filter(this.intervention.responses, {finished: false}).length>0;
			}
		},
		beforeMount: function () {
			this.event = this.getEvent(this.eventid);
			this.intervention = this.getIntervention(this.eventid);
			if (Object.keys(this.intervention.responses).length==0) {
			    this.showActions = true;
            }
		},
		data: function () {
			return {
				event: {},
				intervention: {},
                showActions: false,
                labelaskhour: false,
			}
		},
        methods: {
			...mapActions('interventions', ['finishIntervention', 'addResponse', 'removeResponse']),
		    closeIntervention(etatid){
			    Dashmix.layout('header_loader_on');
		        this.finishIntervention({uid: this.eventid, etatid: etatid, finished: true});
		        this.$router.push({name: 'home'});
            },
		    markAsFinished(){
			    Dashmix.layout('header_loader_on');
		        this.finishIntervention({uid: this.eventid, finished: true});
		        this.$router.push({name: 'home'});
            },
			editStart(){
				this.labelaskhour = 'Début';
			},
			editEnd(){
				this.labelaskhour = 'Fin';
			},
			setEditingHour(value){
				if (this.labelaskhour =='Fin') {
					this.intervention.ended_at = value;
					this.finishIntervention({uid: this.eventid, end: value});
				}else {
					this.intervention.started_at = value;
					this.finishIntervention({uid: this.eventid, start: value});
				}
				this.labelaskhour = '';
			},
			setStart(){
				this.intervention.started_at = moment().format('HH:mm');
				this.finishIntervention({uid: this.eventid, start: this.intervention.started_at});
			},
			setEnd(){
				this.intervention.ended_at = moment().format('HH:mm');
				this.finishIntervention({uid: this.eventid, end: this.intervention.ended_at});
			},
            continueReponse(responseid){
			    Dashmix.layout('header_loader_on');
			    this.$router.push({name: 'resultMission', params:{responseid:responseid, eventid: this.eventid}});
            },
            async createResponseAndRoute(action) {
			    Dashmix.layout('header_loader_on');
				let prefilled = {};
				
				if (this.event['pre-filled'] && this.event['pre-filled'][action.form_id]) {
					prefilled = this.event['pre-filled'][action.form_id];
				}
				
				let responseid = await this.addResponse({uid: this.eventid, action: action, prefilled:prefilled});

			    this.$router.push({
                    'name': 'resultMission',
                    'params': {
                        eventid: this.eventid,
                        responseid: responseid
                    }
                });
            },
            removeResponses(responseid) {
			    Dashmix.layout('header_loader_on');
                if(confirm('Voulez-vous supprimer ce formulaire ?')) {
                    this.removeResponse({uid: this.eventid, responseid:responseid})
					if (Object.keys(this.intervention.responses).length==0) {
						this.showActions = true;
					}
                }
			    Dashmix.layout('header_loader_off');
            },
			downloadFile(id, libelle){
				api.getFile({ url: 'intervention/fichiers/'+id+'/get', filename: libelle})
			}
        }
	}
</script>

<style>
	.btn .ribbon-box {
		height: 1.75rem;
    	line-height: 1.75rem;
	}
</style>
