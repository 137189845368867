<template>
	<div>
		<template v-for="(events, date) in getEvents(period)">
			<ul class="list-group">
				<div class="bg-light">
					<div class="d-flex align-items-end justify-content-center my-2">
						<div class="font-size-h1 font-weight-bold text-info mr-2">
							{{getDay(date)}}
						</div>
						<div class="font-weight-bold font-size-sm text-dark-50">
							{{getDayOfWeek(date)}} <br>
							{{getMonth(date)}}
						</div>
					</div>
				</div>
				<template v-for="event in events">
					<list-one-event :event="event" :key="event.uid"></list-one-event>
				</template>
			
			
			</ul>
		</template>
	</div>
</template>

<script>
	import moment from 'moment';
	moment.locale('fr');
	import {mapGetters} from 'vuex';
	import ListOneEvent from './ListOneEvent.vue';
	
	export default {
		name: 'ListEvents',
		props: ['period'],
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('events', [
				'getEvents',
			]),
		},
		mounted: async function(){
			await this.$store.dispatch('events/refresh')
		},
		components: {
			ListOneEvent
		},
		methods: {
			getDay(date){
				return moment(date).format('D');
			},
			getDayOfWeek(date){
				return moment(date).format('dddd');
			},
			getMonth(date){
				return moment(date).format('MMM');
			},
		}
	};
</script>
