import api from '@/services/api'

const store = {
  namespaced: true,
  state: {
    forms: null
  },
  mutations: {
    setforms (state, forms) {
      console.log('setforms', forms);
      state.forms = forms
    }
  },

  actions: {

    reset ({ commit, state }) {
      console.log('reset form');
      commit('setforms', null)
    },
    async refresh ({ commit, state }, ids) {

      try {
        let call = await api.post({url: 'intervention/formularies/get', params: {ids: ids}})
        commit('setforms', call.response)
      }catch (err) {
        console.log('forms refresh', err);
      }
    }
  },
  getters: {
    getforms: state => {
      return state.forms
    },
    getform:  (state) => (formid) => {

      if(!state.forms || !state.forms[formid]){
        return null;
      }
      return state.forms[formid];
    },
  },
  modules: {}
}

export default store